import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Headrepage from "../../components/Homepages/Headerpage/StudentsHederpage";
import EyesOpen from "../../iconsopen/EyesOpen";
import EyesClose from "../../iconsopen/EyesClose";
import Loginimage from "../../assets/pexels-login.png";
import Google from "../../assets/google.png";
//import LinkedIn from "../../assets/linkedin.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import ReCAPTCHA from "react-google-recaptcha";
import './Login.css';

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  //const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [data, setData] = useState({
    username: "",
    password: "",
    userType: "student"
  });
  
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const error = searchParams.get('error');
    if (error) {
      if (error === 'registration_required') {
        toast.error('User not found. Please register first.');
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    }
  }, [location.search]);

  // const onChangeRecaptcha = (token) => {
  //   setRecaptchaToken(token);
  // };

  const loginwithgoogle = () => {
    window.location.href = "https://api.huebits.in/api/auth/google";
  };

  // const loginWithLinkedIn = () => {
  //   window.location.href = "https://api.huebits.in/api/auth/linkedin/callback";
  // };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    //console.log('Updated user data:', data);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    //console.log('Submitted user data:', data); 

    // if (!recaptchaToken) {
    //   toast.error("Please complete the CAPTCHA");
    //   setLoading(false);
    //   return;
    // }

    const toastId = toast.loading("Your login request is being processed...");
    try {
      const response = await axios.post("https://api.huebits.in/api/login", 
        { ...data }, 
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const dataResponse = response.data;

      if (dataResponse.error) {
        toast.update(toastId, { render: dataResponse.message, type: "error", isLoading: false, autoClose: 5000 });
      } else {
        toast.update(toastId, { render: dataResponse.message, type: "success", isLoading: false, autoClose: 8000 });
        setTimeout(() => {
          localStorage.setItem('token', dataResponse.token);
          navigate("/dashboard");
        }, 1500);
      }
    } catch (error) {
      let errorMessage = "An unexpected error occurred. Please try again.";

      if (error.response) {
        errorMessage = error.response.data.message || errorMessage;
      } else if (error.request) {
        errorMessage = "Network error: Please check your connection and try again.";
      } else {
        errorMessage = `Request error: ${error.message}`;
      }

      toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="students-flex">
      <Headrepage />
      <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
      <div className="studentssign-cssimagesflex-color">
        <div className="studentssign-cssimagesflex">
          <div className="students-signimage">
            <div className="students-login-flex" id="students-signimage">
              <p className="students-center-login">
                Student Log in
              </p>
              <form onSubmit={submitHandler}>
                <div className="form-group">
                  <label htmlFor="email" className="loginlabel">Email</label>
                  <input 
                    type="email" 
                    className="login-form-control" 
                    id="username" 
                    name="username" 
                    placeholder="Email Id" 
                    autoFocus="off" 
                    value={data.username} 
                    disabled={loading} 
                    onChange={handleOnChange} 
                    required 
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password" className="loginlabel">Password</label>
                  <input 
                    type={showPassword ? "text" : "password"} 
                    className="login-form-control" 
                    id="password" 
                    name="password" 
                    placeholder="Enter your password" 
                    autoFocus="off" 
                    value={data.password} 
                    onChange={handleOnChange} 
                    required 
                  />
                  <div className='icons-password-login' onClick={() => setShowPassword(prev => !prev)}>
                    {showPassword ? <EyesOpen /> : <EyesClose />}
                  </div>
                </div>
                <div className="students-checkboxlogin">
                  {/* <ReCAPTCHA 
                    sitekey="6LdcQAkqAAAAAIeIrHfqOtb_Jb9wst0fWQ9tvaGb" 
                    onChange={onChangeRecaptcha}
                  /> */}
                </div>
                <div>
                  <button type="submit" className="students-signin-btn" value="submit" disabled={loading}>
                    {loading ? 'Log in...' : 'Log in'}
                  </button>
                  <p><Link to="/forgot-password" className="students-frogot-password">Forgot Password</Link></p>
                </div>
              </form>
              <div className="loginhr">
                <hr className="loginhrline" />
                <span className="login-hr-or">Or Continue With</span>
                <hr className="loginhrline1" />
              </div>
              <div className="linkedin-google">
                <div className="signingoogle-google-login">
                  <Link className="google-link" role="button" onClick={loginwithgoogle}>
                    <img className="googleicon" src={Google} alt="" />
                    <span className="signingoogle-google"></span>
                  </Link>
                </div>
                {/* <div className="signingoogle-linkedin-login">
                  <Link className="linkedin-link">
                    <img className="linkedinicon" src={LinkedIn} alt="" />
                    <span className="signingoogle-google"></span>
                  </Link>
                </div> */}
              </div>
              <div className="students-account">
                <p>
                  <span className="students-accounts-p">Don't have an account?{" "} </span>
                  <Link to="/signup" className="loginhere" id="loginhere">
                    Create an account
                  </Link>
                </p>
              </div>
            </div>
            <img className="students-login-image" src={Loginimage} alt=""/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
