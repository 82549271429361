import React from "react";
import "./courses.css";
import ProgressBar from "@ramonak/react-progress-bar";

const CoursesProgress = () => {
  const getColorForProgress = (progress) => {
    return progress >= 1 && progress <= 100 ? "#052659" : "Indigo";
  };

  const courses = [
    { name: 'course1', completion: 0 },
    { name: 'course2', completion: 0 },
    { name: 'course3', completion: 0 },
    { name: 'course4', completion: 0 },
    { name: 'course5', completion: 0 }
  ];

  return (
    <div className="CoursesProgress">
      <h1 className="CoursesProgress1">Courses Progress</h1>
      <div className="courses-container">
        {courses.map((course, index) => (
          <div className="course" key={index}>
            <div style={{ padding: "0px" }}>
              <h2 className="course-progress-title">{course.name}</h2>
              <ProgressBar
                completed={course.completion}
                animateOnRender={true}
                bgColor={getColorForProgress(course.completion)}
                className="course-bar"
                text={`${course.completion}%`}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoursesProgress;
