import React, {useRef} from "react";
import Headerpage from "../../Homepages/Headerpage/CompainesHeaderpage";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight,faStar} from "@fortawesome/free-solid-svg-icons";
import CountUp from 'react-countup';
import  FutureofIoT from "../../../assets/icons/Future-of-IoT.png";
import { Internetlearning } from "./Learning-paths";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaArrowUp } from 'react-icons/fa';
import Empower from "../../../assets/Empower.jpg";
import Iot1 from "../../../assets/icons/1.png";
import Iot2 from "../../../assets/icons/7.png";
import Iot3 from "../../../assets/icons/3.png";
import Iot4 from "../../../assets/icons/4.png";
import Iot5 from "../../../assets/icons/5.png";
import Iotimage from "../../../assets/Internshipiot.png";
import Bestplan from "../../studentspages/Students/Bestplanyear";
import Smartcity from "./Projects";
import IoT from "../../../assets/New folder/12.png";
import creativity from "../../../assets/New folder/11.png";
import connection from "../../../assets/New folder/13.png";
import Footer from "../../Homepages/Footer/Companies-footer";
import Reveal from '../../Homepages/hook/useScrollposition';

const InternetofThings = () => {
  const studentslearningSectionRef = useRef(null);
  const footerSectionRef = useRef(null);

  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToStudentslearning = () => {
    studentslearningSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

    return (
        <div className="students-flex companies-flex">
        <Headerpage />
        <div className="homepagecolores homepagecolores-1">
        <Reveal>
        <div ref={footerSectionRef} className="courses-iot-empowering">
        <ul>
          <li>
          <h1 className="IoTUniverse-h1">Empowering Innovators</h1>
          <h1 className="IoTUniverse-h1">In The IoT Universe</h1>
          <p className="IoTUniverse-p">From Learning to Launch: <span>Your Complete IoT Journey Starts Here</span></p></li>
          <p className="IoTUniverse-btn-p">
            <button className="IoTUniverse-btn" onClick={scrollToStudentslearning}>Dive into IoT Excellence 
            <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
          </button></p>
        </ul>
          <img className="thirdman-iot" src={Empower} alt=""/>
        </div>
        </Reveal>
        <Reveal>
        <div className="iotuniversediv-career-pathseses">
        <div className="iotuniversediv-career-pathses">
        <h1 className="iotuniversediv-career-path-h1">About This Career Path</h1>
        <div className="iotuniversediv-career-path">
          <div className="iotuniversediv-career-paths">
            <p className="iotuniversediv-career-path-p">In the dynamic field of IoT,
             companies are seeking skilled professionals 
             who can leverage connected technologies to 
             drive innovation and efficiency. 
             This Career Path provides you with a comprehensive 
             education through specialized courses,
             hands-on industrial projects, and valuable internships. 
             You'll learn to design and implement IoT solutions, 
             manage sensor data, develop real-time analytics, and ensure security. 
             By engaging in real-world projects and internships, you'll gain practical 
             experience that will prepare you for a successful career in the IoT industry.</p>
          </div>
          <div className="iotuniversediv-career-path-salarys">
          <div className="iotuniversediv-career-path-salary">
            <p className="iotuniversediv-career-path-salary-p">Average Salary (US)</p>
            <p className="iotuniversediv-career-path-salary-p1">$125,000</p>
          </div>
          </div>
        </div>
        </div>
        </div>
        </Reveal>
        <div className="IoTUniversediv">
        <Reveal>
          <h1 className="IoTUniverse-1h1">
          Discover IoT Opportunities: Access Courses, Hands-On Projects, and Real Industry Experience
          <p className="IoTUniverse-iotp1"></p>
          </h1>
          <h1 className="IoTUniverse1-h1">
          <img className="futureofiot-icon" src={FutureofIoT} alt=""/>
          Step Into The Future Of IOT
          </h1>
          <p className="IoTUniverse1-p">Empowering learners and professionals to innovate and excel</p>
          </Reveal>
          <Reveal>
          <div className="IoTUniverse-internet-cardflexs">
              <div className="IoTUniverse-card1">
                <img className="IoTUniverse-iote" src={IoT} alt="Internet"/>
                <div className="IoTUniverse-card-body">
                <h1 className="IoTUniverse-card-titles">With <CountUp end={40} duration={5} />+ courses covering every aspect of IoT.</h1>
              </div>
              </div>
              <div className="IoTUniverse-card2">
                <img className="IoTUniverse-iote-projects" src={creativity} alt="Internet"/>
                <div className="IoTUniverse-card-body">
                <h1 className="IoTUniverse-card-titles"><CountUp end={100} duration={10} />+ hands-on projects to spark your IoT creativity</h1>
              </div>
              </div>
              <div className="IoTUniverse-card3">
                <img className="IoTUniverse-iote" src={connection} alt="Internet"/>
                <div className="IoTUniverse-card-body">
                <h1 className="IoTUniverse-card-title">Explore <CountUp end={1000} duration={15} />+ IoT internship opportunities for industry connections.</h1>
              </div>
              </div>
              </div>
              </Reveal>
              </div>
              <div className="IoTUniverse2" ref={studentslearningSectionRef}>
              <Reveal>
               <h1 className="IoTUniverse2-h1-mastery">Explore the World of IoT Mastery: Courses + Projects + Experience</h1>
              </Reveal>
              <Reveal>
              <Internetlearning />
              </Reveal>
          </div>
          <div className="IoTUniverse3">
          <Reveal>
          <h1 className="IoTUniverse3-h1">Hands-On Projects: Create, Innovate, Transform</h1>
            <p className="IoTUniverse3-h1-1">Engage in projects across various fields and industries</p>
          </Reveal>
            <div>
          <Reveal>
            <Smartcity />
          </Reveal>
          </div>
          </div>
          <div className="IoTUniverse4">
          <Reveal>
            <h1 className="IoTUniverse4-h1">Guaranteed Internships and Placement Assistance: Master IoT Skills With Us</h1>
          </Reveal>
          <Reveal>
            <div className="IoTUniverse4-flex">
              <ul>
                <li className="IoTUniverse4-li">
                <FontAwesomeIcon icon={faStar}  className="IoTUniverse4-li-fastar"/>
                  <p className="IoTUniverse4-p">
                  Expert Training: Master IoT with comprehensive courses and hands-on projects.
                  </p>
                </li>
                <li className="IoTUniverse4-li">
                <FontAwesomeIcon icon={faStar}  className="IoTUniverse4-li-fastar"/>
                <p className="IoTUniverse4-p">
                  Guaranteed Internships: 100% internship placement guarantee with paid opportunities.
                </p>
                </li>
                <li className="IoTUniverse4-li">
                <FontAwesomeIcon icon={faStar}  className="IoTUniverse4-li-fastar"/>
                <p className="IoTUniverse4-p">
                  Industry Experience: Unique exposure to real-world industry practices.
                </p>
                </li>
                 <li className="IoTUniverse4-li">
                 <FontAwesomeIcon icon={faStar}  className="IoTUniverse4-li-fastar"/>
                 <p className="IoTUniverse4-p">
                  Placement Assistance: Dedicated support to help you secure a job in the IoT field.
                </p>
                </li>
                </ul>
                <img className="IoTUniverse4-imgs-iot" src={Iotimage} alt=""/>
            </div>
            </Reveal>
         </div>
         <div className="IoTUniverses4">
          <h1 className="IoTUniverses4-h1">IoT Learning Paths</h1>
          <p className="IoTUniverses4-p">Find Your Ideal Path: Choose from our expert-designed learning paths and start mastering IoT today.</p>
          <ul>
            <p className="IoTUniverses4-li-p">
              <img src={Iot1} alt="" className="IoTUniverses4-iot-img"/>
            <li className="IoTUniverses4-li">IoT Developer</li>
            </p>
            <p className="IoTUniverses4-li-p">
            <img src={Iot2} alt="" className="IoTUniverses4-iot-img"/>
            <li className="IoTUniverses4-li">IoT Data Scientist</li>
            </p>
            <p className="IoTUniverses4-li-p">
            <img src={Iot3} alt="" className="IoTUniverses4-iot-img"/>
            <li className="IoTUniverses4-li">Industrial IoT and Industry 4.0</li>
            </p>
            <p className="IoTUniverses4-li-p">
            <img src={Iot4} alt="" className="IoTUniverses4-iot-img"/>
            <li className="IoTUniverses4-li">IoT Security Specialist</li>
            </p>
            <p className="IoTUniverses4-li-p">
            <img src={Iot5} alt="" className="IoTUniverses4-iot-img"/>
            <li className="IoTUniverses4-li">IoT Hardware Engineer</li>
            </p>
          </ul>
          <p className="IoTUniverses4-p1">Custom Solutions & Flexible Learning: Need a tailored program? Our AI-generated learning paths 
            offer personalized options for all skill levels—beginner to expert—ensuring you meet your goals 
            and boost your organization's success.</p>
         </div>
         <div className="IoTUniverse5">
          <Reveal>
          <h1 className="IoTUniverse5-h1">What Our Customers Are Saying</h1>
            </Reveal>
            </div>
            <div className="IoTUniverse6">
            <Reveal>
             <p className="IoTUniverse6-h1">Subscribe: Unlock The IoT Advantage</p>
            </Reveal>
            <div>
            <Reveal>
             <Bestplan />
            </Reveal>
            </div>
            </div>
            <div className="homepage-footers-images">
            <Footer />
             <footer className="homepage-footers">
             <hr className='homepage-footer-hr'/>
             <div className="footer-social">
             <div className="homepage-footer-copyright">
              <p>© Huebits[2024].All Rights Reserved.</p>
             </div>
             <div className='footer-social-icons'>
                <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                <Link><FaFacebook className="social-icon" /></Link>
                <Link><FaTwitter className="social-icon" /></Link>
                <Link><FaInstagram className="social-icon" /></Link>
                <Link onClick={scrollToFooterSection}>
                <FaArrowUp className="homepage-social-icon-link" />
                </Link>
            </div>
            </div>
          </footer>
      </div>
    </div>
    </div>
    )
}


export default InternetofThings;