import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Huebits from "../../../assets/hue2.png";
import "./Header.css";

const Pages = () => {
  const [menuActive, setMenuActive] = useState(false);


  const toggleMenu = () => {
    console.log("Toggle menu clicked");
    setMenuActive(!menuActive);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };
  
  return (
    <div className="hubits-div">
     <Link to="/">
      <img className="homehuebits" src={Huebits} alt=""/>
      </Link>
      <div className="hubits1">
        <div className="responsive-menu" onClick={toggleMenu}>
          <MenuOutlinedIcon className="menu-icon" />
        </div>
        <div className="hubitsp-1">
          <ul className={`hubits ${menuActive ? 'active' : ''}`} id="huebits">
            <li className="companies-pages"><Link to='/companies' className="aboutuses" onClick={closeMenu}>Companies</Link></li>
            <li>
            <Link to="/companiessignin" className="aboutuses" >Sign in</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pages;