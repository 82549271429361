import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Data from "./data";
import Bigdata from "./bigdata";
import './Customized.css';

const Customized = () => {
    const [activedataLink, setActivedataLink] = useState(null);

    const handleLinkClick = (linkName) => {
        setActivedataLink(linkName);
    }

    return (
        <div className="customizedscroling">
           <div className="databigdata1">
            <div className="databigdata">
            <Link
                className={activedataLink === 'datascience' ? 'datascienceLink-link' : 'datascienceLink-link'}
                onClick={() => handleLinkClick('datascience')}
            >
                <button className='datascience'>IOT</button>
            </Link>
            <Link
                className={activedataLink === 'bigdata' ? 'bigdataLink-link' : 'bigdataLink-link'}
                onClick={() => handleLinkClick('bigdata')}
            >
                <button className='bigdata'>Data Science</button>
            </Link>
          </div>
          <div className="">
            {activedataLink === 'datascience' && <Data />}
            {activedataLink === 'bigdata' && <Bigdata />}
        </div>
        </div>
    </div>
    );
}

export default Customized;