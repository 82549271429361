import React from "react";

const courses = [
    "IoT using Arduino",
    "IoT using Arduino",
    "IoT using Arduino",
    "IoT using Arduino",
    "Amazon Web Services (AWS) IoT Training",
    "IoT using Arduino",
    "IoT using Arduino",
    "IoT using Arduino",
    "Smart Shoe Development Using ESP32 and Blynk",
    "IoT using Arduino",
];

const Favorite = () => {
    const chunkedCourses = [];
    for (let i = 0; i < courses.length; i += 2) {
        chunkedCourses.push(courses.slice(i, i + 2));
    }

    return (
        <div className="courseslist-courses">
            <div className="courseslist-completed-courses">
                <h1 className='courseslist'>Completed Courses</h1>
                {chunkedCourses.map((courseGroup, index) => (
                    <div key={index} className="courseslist-course-row">
                        {courseGroup.map((course, subIndex) => (
                            <div key={subIndex} className="courseslist-course-box">
                                <p className="courseslistp-myactive-courses">{course}
                                </p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Favorite;
