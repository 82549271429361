import React, {useRef} from "react";
import { Link } from "react-router-dom";
import Headerpage from "../../Homepages/Headerpage/CompainesHeaderpage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck,faArrowRight  } from "@fortawesome/free-solid-svg-icons";
import product from "../../../assets/Thirdmana4.png";
import customers1 from "../../../assets/customers1.png";
import customers2 from "../../../assets/customers2.png";
import customers3 from "../../../assets/customers3.jpg";
import customers4 from "../../../assets/customers4.png";
import {Companieslearningpaths,Companieslearning} from "./companies-learning-path";
import {Customersreviews} from "./combinepage-reviews";
import { BasketSimpleIcon, MeetingIcon, ShareIcon, WebDesignIcon } from "./icons";
import Background from "./Background";
import Line from "./picture";
import { FaFacebook, FaTwitter, FaInstagram,FaLinkedin, FaArrowUp } from 'react-icons/fa';
import Footer from "../../Homepages/Footer/Companies-footer";
import Reveal from '../../Homepages/hook/useScrollposition';
import "./homepage.css";


const Compaines = () => {
  const compainesSectionRef = useRef(null);
  const footerSectionRef = useRef(null);

  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToCompainesSection = () => {
    compainesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

    return (
      <div className="students-flex companies-flex">
      <Headerpage />
        <div className="homepagecolores">
        <>
        <div className="aboutus-1">
        <Reveal>
        <div className="homepagecolor">
            <div ref={footerSectionRef} className="getstarted-flex companies-getstarted-flex-1">
            <ul>
                <li><h1 className="brilliant">Where Brilliant Minds</h1>
                <h2 className="build">Guide You Build Your Product </h2>
                <p className="spansexports">Our experts and innovators help Organizations Build Prototypes | Proof of Concepts  |  Products</p></li>
                <div className="buttonget-companies">
                <Link to="/companiessignin"><button className="compaines-buttonget-1">Get Started
                <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                </button></Link>
                <button className="compaines-buttonget-1 link" onClick={scrollToCompainesSection}>Submit Project
                <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                </button>
                </div>
              </ul>
            <div className="huebitsimgdiv">
              <img className="Thirdman" src={product} alt="" />
            </div>
            </div>
            </div>
            </Reveal>
            <div className="customersbuild">
            <Reveal>
              <h1 className="customersbuild-h1">Some Of Our Happy Customers</h1>
              <div className="customersbuild-imgs">
              <img className="customers-imgs" src={customers1} alt="" />
              <img className="customers-imges" src={customers2} alt="" />
              <img className="customers-imgs" src={customers3} alt="" />
              <img className="customers-imgs" src={customers4} alt="" />
              </div>
            </Reveal>
            </div>
            <div className="companies-page-center">
            <Reveal>
              <h1 className="companiess">Industry Solutions: From Problem To Prototype</h1>
              <p className="companiesspan">We offer solutions in the following domains</p>
            </Reveal>
              <Companieslearningpaths />
              <div className="solutions-for-Companies">
            <Reveal>
              <h1 className="solutions-for-Companies-companiess">Explore The Benefits</h1>
                <Companieslearning />
                </Reveal>
            </div>
            </div>
            {/* <div className="carouselsimages">
            </div> */}
            <div className="HowitWorks1">
            <Reveal>
              <h1 className="howitworks-1">How It Works</h1>
            </Reveal>
            <Reveal>
              <div className="HowitWorks">
              <div className="sharpost-1">
              <p className="ShareIcon"><ShareIcon /></p>
              <p><span className="spans-1">Submit Your Project</span></p>
              </div>
              <div className="meetingiconsexperts">
              <p className="MeetingIcon"><MeetingIcon /></p>
              <p className="executionplan"><span className="spans-1">Get Feasibility Report</span></p>
              </div>
              <div className="webdesignicons">
              <p className="WebDesignIcon"><WebDesignIcon /></p>
                <p className="timeline"><span className="spans-1">Project Execution</span></p>
              </div>
              <div className="basketsimapleicons">
              <p className="BasketSimpleIcon"><BasketSimpleIcon /></p>
              <p className="theirportfolios"><span className="spans-1">Project Delivery</span></p>
              </div>
              </div>
              <div className="brz-img-1-container">
            <Line />
            </div>
            </Reveal>
            </div>
            <div>
            <div ref={compainesSectionRef} className="Choose-Services">
            <Reveal>
              <div className="Choose-Service">
              <h1 className="Choose">Choose Service</h1>
              </div>
              <div className="Choose-Serviceses">
                  <p>
                  <div className="inr2-3-lakh-flex">
                  <div className="inr2-3-box">
                  <div className="companies-inr2-3">
                    <div className="Productsp">
                    <h1 className="companies-products-1">Rapid POC & Product Development</h1>
                    </div>
                    <div className="flex-Internet-of-Things">
                      <div className="Internet-of-Things-1">
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                         Internet of Things</p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Embedded Systems</p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Data Science</p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Web Application Development</p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        White Label Product</p>
                       
                        </div>
                        <div className="btn-post-a-projects-links-div">
                        <Link to="/companiessignin"><button className="btn-post-projects-link-manth">Post a Project
                        <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                        </button></Link>
                        </div>
                    </div>
                    </div>
                    </div>
                    <div className="inr2-4-box">
                    <div className="companies-inr2-4">
                    <div className="Productsp">
                    <h1  className="companies-products-1">Expert Research Consultancy</h1>
                    </div>
                    <div className="flex-Internet-of-Things">
                      <div className="Internet-of-Things-1">
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Data Science </p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Digital Marketing</p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Content Development</p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        Business Intelligence</p>
                        </div>
                        <div className="btn-post-a-projects-links-div1">
                        <Link to="/companiessignin"><button className="btn-post-projects-link">Post a Project
                        <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                        </button></Link>
                        </div>
                    </div>
                  </div>
                  </div>
                  </div>
                  </p>
              </div>
              </Reveal>
            </div>
            <div className="compaines-creativeproducts-reviews">
                <Reveal>
                  <Customersreviews />
                </Reveal>
            </div>
            </div>
            <Reveal>
         <div className="backgroundimage-1">
          <Background />
        </div>
        </Reveal>
        <div className="homepage-footers-images">
        <Footer />
        <footer className="homepage-footers">
        <hr className='homepage-footer-hr'/>
        <div className="footer-social">
        <div className="homepage-footer-copyright">
          <p>© Huebits[2024].All Rights Reserved.</p>
        </div>
        <div className='footer-social-icons'>
          <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon" /></Link>
          <Link><FaFacebook className="social-icon" /></Link>
          <Link><FaTwitter className="social-icon" /></Link>
          <Link><FaInstagram className="social-icon" /></Link>
          <Link onClick={scrollToFooterSection}>
          <FaArrowUp className="homepage-social-icon-link" />
          </Link>
        </div>
        </div>
        </footer>
      </div>
      </div>
      </>
    </div>
    </div>
    )
}


export default Compaines;