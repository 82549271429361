import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SmartCity from "../../../assets/New folder/7.png";
import LightingSystem from "../../../assets/New folder/10.png";
import SmartManufacturing from "../../../assets/New folder/6.png";
import "./Projects.css";

const Slide = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        prevArrow: <button className="slick-prev"><i className="fas fa-chevron-left"></i></button>,
        nextArrow: <button className="slick-next"><i className="fas fa-chevron-right"></i></button>,
        responsive: [
            {
                breakpoint: 1024, 
                settings: {
                    slidesToShow: 2, 
                }
            },
            {
                breakpoint: 768, 
                settings: {
                    slidesToShow: 2, 
                }
            },
            {
                breakpoint: 480, 
                settings: {
                    slidesToShow: 1, 
                }
            }
        ]
    };

    return (
        <div className="smartcity-slider-container">
        <Slider {...settings}>
            <div className="iot-smartcity-slide-item">
                <img className="SmartCity" src={SmartCity} alt="Smart City"/>
                <div className="IoTUniverse3-card-body">
                    <h1 className="IoTUniverse3-card-title-paths">Smart City</h1>
                    <p className="IoTUniverse3-card-title-paths-p">
                        Implementing an ESP32-based IoT solution for Smart Cities, 
                        integrating sensors and actuators for data collection and 
                        urban management.
                    </p>
                </div>
            </div>
            <div className="iot-smartcity-slide-item">
                <img className="SmartCity" src={LightingSystem} alt="Smart Street Lighting System"/>
                <div className="IoTUniverse3-card-body">
                    <h1 className="IoTUniverse3-card-title-paths">Smart Street Lighting System</h1>
                    <p className="IoTUniverse3-card-title-paths-p">
                        Developing ESP32 Arduino-based smart street lighting system 
                        for energy-efficient, automated illumination, enhancing 
                        urban safety and sustainability
                    </p>
                </div>
            </div>
            <div className="iot-smartcity-slide-item">
                <img className="SmartCity" src={SmartManufacturing} alt="Smart Manufacturing"/>
                <div className="IoTUniverse3-card-body">
                    <h1 className="IoTUniverse3-card-title-paths">Smart Manufacturing</h1>
                    <p className="IoTUniverse3-card-title-paths-p">
                        Creating Raspberry Pi-based smart manufacturing 
                        system with Python for data analytics, process 
                        automation, and real-time monitoring in factories
                    </p>
                </div>
            </div>
        </Slider>
        </div>
    );
};

export default Slide;
