import React from 'react';
import Typical from 'react-typical';

const Type = () => {
  return (
    <div>
      <h1 className="huebits-homepages-spark-spark">
        <span className="white-text">
          <Typical
            steps={[
              'Experience ', 3000
            ]}
            wrapper="span"
            loop={1}
            className="no-cursor"
          />
        </span>
        <span className="blue-text">
          <Typical
            steps={[
              'Meets ', 3500
            ]}
            wrapper="span"
            loop={1}
            className="no-cursor"
          />
        </span>
        <span className="white-text">
          <Typical
            steps={[
              'Opportunity', 4000
            ]}
            wrapper="span"
            loop={1}
            className="no-cursor"
          />
        </span>
      </h1>
    </div>
  );
};

export default Type;











// import React from 'react';
// import Typical from 'react-typical';

// const Type = () => {
//   return (
//     <div>
//       <h1 className="huebits-homepages-spark-spark">
//         <Typical
//           steps={[
//             'Experience Meets Opportunity', 500
//           ]}
//           wrapper="span"
//           loop={1}
//           className="no-cursor"
//         />
//       </h1>
//     </div>
//   );
// };

// export default Type;
