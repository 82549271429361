import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Getaccesstoallcourses from "./Get_access_to_all_courses";

const MyComponent = () => {
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  const handleUserProfile = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.huebits.in/api/learningpaths/courses/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("API Response:", response.data);

      if (response.status === 200) {
        const fetchedCourses = response.data.data?.courses || [];
        setCourses(fetchedCourses);
      } else {
        console.error("API Error:", response.statusText);
        setError("Error fetching data.");
      }
    } catch (error) {
      console.error("Error fetching user details:", error.message);
      setError("Error fetching data.");
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    handleUserProfile();
  }, [handleUserProfile]);

  const chunkedCourses = [];
  for (let i = 0; i < courses.length; i += 2) {
    chunkedCourses.push(courses.slice(i, i + 2));
  }

  return (
    <div className="courseslist-courses">
      <div className="courseslist-myactive-courses">
        <h1 className="courseslist">My Active Courses</h1>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {courses.length === 0 && !loading ? (
          <p className="courseslist-p">No courses available.</p>
        ) : (
          chunkedCourses.map((courseGroup, index) => (
            <div key={index} className="courseslist-course-row">
              {courseGroup.map((course, subIndex) => (
                <div key={subIndex} className="courseslist-course-box">
                  <p className="courseslistp-myactive-courses">
                    {course.courseTitle}
                  </p>
                </div>
              ))}
            </div>
          ))
        )}
        {courses.length === 0 && !loading && <Getaccesstoallcourses />}
      </div>
    </div>
  );
};

export default MyComponent;
