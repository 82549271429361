import React from "react";
import Learningpathprogress from './Learningpathprogress';
import CoursesProgress from "./Coursesprogress";
import ProjectsProgress from "./ProjectsProgress";
import HOEScore from "./HOEScore";
import './dashboardindex.css';

const Dashboard = () => {

  return (
    <div className="Dashboardheader1">
      <div className="dashboardheader2">
  <div className="Learningpathprogess">
        <CoursesProgress />
  </div>
      <div className="ProjectsProgres">
        <Learningpathprogress />
        <ProjectsProgress />
        <HOEScore />
      </div>
    </div>
    </div>
  );
}

export default Dashboard;
