import React from "react";
import Commingsoon from "../../assets/Coming-soon/4.png";
import "./Live-sessions.css";

const LiveSessions = () => {
    return (
        <div className="students-livesessions">
            <div className="students-comingsoon">
            <img src={Commingsoon} alt="comingsoon" className="students-comingsoon-image" />
            <button className="capstonestart livesessions">
            Coming Soon
            </button>
        </div>
        </div>
    )
}

export default LiveSessions;