import React,{useState} from "react";
import {Link} from "react-router-dom";
import './capstoneaimagic.css';

const Capstoneaimagic = () => {
  const [showcapstoneStart, setShowcapstoneStart] = useState(false);
  
  const handleStartClick = () => {
    setShowcapstoneStart(!showcapstoneStart);
  };
    
    return (
        <div className="students-capstone1">
        <div className="capstone1">
          <h1 className="capstone">Capstone AI Magic Gateway to your Capstone</h1>
        <Link to="" style={{ textDecoration: "none" }} >
        <button className="capstonestart" onClick={handleStartClick}>
        Coming Soon
        </button>
        </Link>
        </div>
        </div>
    );
};

export default Capstoneaimagic;